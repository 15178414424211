import { FC } from "react";
import { Experts } from "../components/Experts";
import { Hero } from "../components/Hero";
import { Missions } from "../components/Missions";
import { OurPassion } from "../components/OurPassion";
import { OurTargets } from "../components/OurTargets";

export const Home: FC = () => (
  <>
    <Hero />
    <Missions />
    <OurPassion />
    <OurTargets />
    <Experts />
  </>
);
