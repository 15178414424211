import { NavBar } from "./components/NavBar";
import { BackToTop } from "./components/BackToTop";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home";
import { Team } from "./pages/Team";
import { Footer } from "./components/Footer";
import { Faq } from "./pages/Faq";
import { TalentPool } from "./pages/TalenPool";
import { NotFound } from "./pages/NotFound";
import { Page } from "./pages/Page";

const routes = [
  {
    path: "/",
    title: "U2C - University To Career",
    element: <Home />,
  },
  {
    path: "/talent-pool",
    title: "U2C - Yetenek Havuzu",
    element: <TalentPool />,
  },
  {
    path: "/team",
    title: "U2C - Ekibimiz",
    element: <Team />,
  },
  {
    path: "/faq",
    title: "Sıkça Sorulan Sorular",
    element: <Faq />,
  },
  {
    path: "*",
    title: "U2C 404 - Sayfa bulunamadı",
    element: <NotFound />,
  },
];

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          {routes.map(({ path, element, title }, key) => (
            <Route
              path={path}
              element={
                <Page key={key} title={title}>
                  {element}
                </Page>
              }
              key={key}
            />
          ))}
        </Routes>
        <Footer />
        <BackToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
