import { FC } from "react";

export const OurPassion: FC = () => (
  <section className="pricing-area">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-10">
          <div className="section-title text-center pb-25">
            <h3 className="title">İşimiz/Tutkumuz</h3>
            <p className="text">
              Tutkuların gücüne inanırız. Dünyayı dönüştüren işler tutkuyla
              yapılanlardır. Bizi harekete geçiren de işimize duyduğumuz
              tutkudur.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-7 col-sm-9">
          <div className="pricing-style mt-30">
            <div className="pricing-icon text-center">
              <img
                src="https://assets.u2c.biz/images/our_work.png"
                loading="lazy"
                alt=""
              />
            </div>
            <div className="pricing-header text-center">
              <h5 className="sub-title">İşimiz</h5>
            </div>
            <div className="pricing-list">
              <ul>
                <li>
                  <i className="lni lni-check-mark-circle"></i> İş dünyası ile
                  eğitimde olan gençleri erken dönemde bir araya getirerek
                  sunduğumuz inovatif platformla en doğru eşleşmeyi sağlamaktır.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-7 col-sm-9">
          <div className="pricing-style mt-30">
            <div className="pricing-icon text-center">
              <img
                src="https://assets.u2c.biz/images/our_passion.png"
                loading="lazy"
                alt=""
              />
            </div>
            <div className="pricing-header text-center">
              <h5 className="sub-title">Tutkumuz</h5>
            </div>
            <div className="pricing-list">
              <ul>
                <li>
                  <i className="lni lni-check-mark-circle"></i> Hızla değişen ve
                  dönüşen dünyada aynı hızla iş gücünde farklılaşan ihtiyaçlara
                  çevik bir şekilde karşılık üreten bir sistem inşa ederek
                  insanların iş gücü katılımlarını iyileştirmektir.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
