import { FC, useEffect, ReactElement } from "react";
interface IPageProps {
  children: ReactElement | null;
  title?: string;
}

export const Page: FC<IPageProps> = ({
  children,
  title = "U2C University to Career",
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);
  return <div className="full__page-transition">{children}</div>;
};
