import { FC } from "react";
import { Accordeon } from "../components/Accordeon";

export const Faq: FC = () => (
  <>
    <section
      className="slider_area"
      style={{ backgroundColor: "#162e60", height: 96 }}
    ></section>
    <Accordeon />
  </>
);
