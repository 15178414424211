import { FC, MouseEventHandler, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Logo } from "./Logo";

const menu = [
  { text: "Anasayfa", link: "/" },
  { text: "Yetenek Havuzu", link: "/talent-pool" },
  { text: "Ekibimiz", link: "/team" },
  { text: "S.S.S", link: "/faq" },
];
export const NavBar: FC = () => {
  const { pathname } = useLocation();
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    const scrollListener = () => {
      const scroll = $(window).scrollTop() || 0;
      setIsSticky(scroll < 20);
    };
    $(window).on("scroll", scrollListener);

    return () => {
      $(window).off("scroll", scrollListener);
    };
  }, []);
  const handleCloseOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (e.target) {
      $(".navbar-collapse").removeClass("show");
    }
  };

  return (
    <section className="navbar-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                <Logo dark={!isSticky} />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTwo"
                aria-controls="navbarTwo"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse sub-menu-bar"
                id="navbarTwo"
              >
                <ul className="navbar-nav m-auto">
                  {menu.map(({ text, link }, key) => (
                    <li
                      className={`nav-item ${
                        pathname === link ? "active" : ""
                      }`}
                      key={key}
                    >
                      <NavLink to={link} onClick={handleCloseOnClick}>
                        {text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};
