import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export const NotFound: FC = () => {
  const [redirectDelay, setRedirectDelay] = useState(3);

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      isMounted && setRedirectDelay(redirectDelay - 1);
    }, 1000);

    return () => {
      isMounted = false;
    };
  }, [redirectDelay]);

  if (redirectDelay < 1) return <Navigate to="/" />;

  return (
    <>
      <section
        className="slider_area"
        style={{ backgroundColor: "#162e60", height: 96 }}
      ></section>
      <section className="errorPage">
        <div className="errorPage__content">
          <h1>404</h1>
          <p>
            <strong>Sayfa bulunamadı.</strong> <br />
            {redirectDelay} saniye sonra Ana sayfaya yönlendirileceksiniz.
          </p>
        </div>
      </section>
    </>
  );
};
