import videojs from "video.js";
import "video.js/dist/video-js.css";

const MOBILE_VIDEO_ID = "mobile-video";

const mobileVideoOnEnd = (): void => {
  const player = videojs(MOBILE_VIDEO_ID);
  player.dispose();
  $(`#${MOBILE_VIDEO_ID}`).remove();
};

const destroyVideoOnExit = (): void => {
  const player = videojs(MOBILE_VIDEO_ID);

  if (!player.isFullscreen()) {
    player.dispose();
    $(`#${MOBILE_VIDEO_ID}`).remove();
  }
};

export const createMobileVideo = (): void => {
  const mobileVideo = $("#video-u2c-intro video").clone();

  mobileVideo.attr("id", MOBILE_VIDEO_ID);
  $("body").append(mobileVideo).removeAttr("class");
  const player = videojs(MOBILE_VIDEO_ID);

  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    player.on("fullscreenchange", destroyVideoOnExit);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    player.on("ended", mobileVideoOnEnd);
    player.play();
    player.requestFullscreen();
  }, 100);
};
