import { FC } from "react";

export const OurTargets: FC = () => (
  <section className="pricing-area">
    <div className="container">
      <div className="row justify-content-center">
        <div>
          <div className="section-title text-center pb-10">
            <h3 className="title pb-30">Hedeflerimiz</h3>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-4 col-sm-9 mb-30">
                  <div className="hedefler__section">
                    <h4 className="hedefler__caption">Kısa vadeli</h4>
                    <h5 className="pb-30">▸ 1 Yıl</h5>
                    <ul>
                      <li>2 Üniversite</li>
                      <li>3 Şirket</li>
                      <li>15 Öğrenci</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-9 mb-30">
                  <div className="hedefler__section">
                    <h4 className="hedefler__caption">Orta vadeli</h4>
                    <h5 className="pb-30">▸ 1 - 3 Yıl</h5>
                    <ul>
                      <li>7 Bölge</li>
                      <li>20 Üniversite</li>
                      <li>30 Şirket</li>
                      <li>200 Öğrenci</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-9 mb-30">
                  <div className="hedefler__section">
                    <h4 className="hedefler__caption">Uzun vadeli</h4>
                    <h5 className="pb-30">▸ 3 Yıl ve üstü</h5>
                    <ul>
                      <li>YÖK Tercih Klavuzuna girmek</li>
                      <li>100+ Üniversite</li>
                      <li>500+ Öğrenci</li>
                      <li>100+ Şirket</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="hedefler__wrapper"></div>
            <h4 className="text">
              U2C Birleşmiş Milletler Sürdürülebilir Kalkınma Hedeflerinden
              aşağıdaki amaçlar üzerine çalışmaktadır.
            </h4>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-9 col-md-6">
          <div className="pricing-style ska-targets__4 mt-30">
            <a
              href="https://www.kureselamaclar.org/amaclar/nitelikli-egitim/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="pricing-icon text-center">
                <img
                  src="https://assets.u2c.biz/images/ska/kuresel_amaclar_04_renkli.png"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="pricing-header text-center"></div>
            </a>
          </div>
        </div>

        <div className="col-9 col-md-6">
          <div className="pricing-style ska-targets__8 mt-30">
            <a
              href="https://www.kureselamaclar.org/amaclar/insana-yakisir-is-ve-ekonomik-buyume/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="pricing-icon text-center">
                <img
                  src="https://assets.u2c.biz/images/ska/kuresel_amaclar_08_renkli.png"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="pricing-header text-center"></div>
            </a>
          </div>
        </div>

        <div className="col-9 col-md-6">
          <div className="pricing-style ska-targets__10 mt-30">
            <a
              href="https://www.kureselamaclar.org/amaclar/esitsizliklerin-azaltilmasi/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="pricing-icon text-center">
                <img
                  src="https://assets.u2c.biz/images/ska/kuresel_amaclar_10_renkli.png"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="pricing-header text-center"></div>
            </a>
          </div>
        </div>

        <div className="col-9 col-md-6">
          <div className="pricing-style ska-targets__17 mt-30">
            <a
              href="https://www.kureselamaclar.org/amaclar/amaclar-icin-ortakliklar/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="pricing-icon text-center">
                <img
                  src="https://assets.u2c.biz/images/ska/kuresel_amaclar_17_renkli.png"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="pricing-header text-center"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);
