import { FC, Fragment } from "react";
import Slider, { CustomArrowProps } from "react-slick";

const PrevArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  const disabled = currentSlide === 0;
  return (
    <span
      {...props}
      className={`prev slick-arrow ${disabled ? "slick-disabled" : ""}`}
      aria-hidden={disabled}
      aria-disabled={disabled}
    >
      <i className="lni lni-arrow-left"></i>
    </span>
  );
};
const NextArrow = ({
  slideCount,
  currentSlide,
  ...props
}: CustomArrowProps) => {
  const disabled = currentSlide === slideCount;
  return (
    <span
      {...props}
      className={`next slick-arrow ${disabled ? "slick-disabled" : ""}`}
      aria-hidden={disabled}
      aria-disabled={disabled}
    >
      <i className="lni lni-arrow-right"></i>
    </span>
  );
};
const slideOptions = {
  dots: false,
  arrows: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 800,
  slidesToShow: 1,
};
const data = {
  title: "Uzmanlar Ne Diyor?",
  text: [
    "İnsan kaynakları yönelimleri araştırma ve işgücü piyasa araştırmaları kuruluşlarından oluşan yararlandığımız kaynaklar işimize olan ihtiyacı vurgulamaktadır.",
    "Özellikle nesnelerin interneti, yapay zeka ve öğrenen makinelerin iş dünyasında daha çok alan kazanmaları sonucu yeni bir şirket - çalışan ilişkisinin inşa edilmesi kaçınılmazdır.",
    "Tam bu noktada çevik ve inovatif sistemlere hiç olmadığı kadar ihtiyaç duymaktayız.",
  ],
  slides: [
    {
      text: [
        " “Beceriler doğru yönetilmediği takdirde, şirketler içinbüyük bir risk ve dezavantaj oluşturuyor.",
        "Türkiye’de ve dünyada firmaların, bu öngörülemez ama yenilikçi döneme kolayca adapte olabilmeleri için çalışan deneyimi ve insan kaynakları stratejilerini kısa, orta ve uzun vadeli planlarla gözden geçirmeleri gerekiyor.”",
      ],
      title: "PERYÖN Dergisi",
      subTitle: "94. Sayı",
    },
    {
      text: [
        "“Türkiye’deki işgücünün yetkinlik setlerinin büyük ölçüde geliştirilmesi gerekebileceği sonucuna varıyoruz. Mevcut yetkinliklerin geliştirilmesi ve yeni yetkinlikler kazandırma çalışmaları işgücünde sürdürülebilir bir değişim elde etmek açısından önem taşıyacaktır.",
        "Şirketler, ihtiyaç duydukları yetkinlikleri farklı yetenek programları ile kazanabilirler. Özellikle yeni olan yetkinlikleri geliştirmek için bu alanlarda yeni roller yaratma ve bu rollerdeki kişilere odaklı eğitim programları başlatabilirler.”",
      ],
      title: "McKinsey İşimizin Geleceği",
      subTitle: "Ocak 2020 Raporu",
    },
    {
      text: [
        "“’Mercer’in 2020 Küresel Yetenek Yönelimleri araştırmasına katılan şirketlerin yüzde 99'u 2020’de dönüşüm sürecinde olacaklarını; ancak mevcut işgücünün kapasite ve beceri eksikliğinin dönüşümün önündeki en büyük bariyer olduğunu belirtiyor. Bu anlamda yeni beceriler kazanmaya (Reskilling) yönelik yatırımların ekonomik gerileme sürecinde de önemli ölçüde devam edeceği belirtiliyor.",
        "Özellikle yeni beceri yatırımları kapsamında üniversiteden yeni mezun olmuş gençlerin önemli bir potansiyel olabileceği düşünülüyor. Ancak bunun için şirketlerin çalışan yetiştirme eğitimleri geliştirmesi gerekiyor.”",
      ],
      title: "Mercer",
      subTitle: "2020 Küresel Yetenek Yönelimleri Raporu",
    },
  ],
};
export const Experts: FC = () => {
  return (
    <section className="testimonial-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-5 col-lg-6">
            <div className="testimonial-left-content mt-45">
              <h4 className="title">{data.title}</h4>
              <ul className="testimonial-line">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <p className="text">
                {data.text.map((sentence, key) => (
                  <Fragment key={key}>
                    {sentence}
                    <br />
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="testimonial-right-content mt-50">
              <div className="quota">
                <i className="lni lni-quotation"></i>
              </div>
              <Slider
                className="testimonial-content-wrapper testimonial-active"
                {...slideOptions}
              >
                {data.slides.map(({ text, subTitle, title }, slide) => (
                  <div className="single-testimonial" key={slide}>
                    <div className="testimonial-text">
                      <p className="text">
                        {text.map((sentence, key) => (
                          <Fragment key={key}>
                            {sentence}
                            <br />
                          </Fragment>
                        ))}
                      </p>
                    </div>
                    <div className="testimonial-more-text"></div>
                    <div className="testimonial-author d-sm-flex justify-content-between">
                      <div className="author-info d-flex align-items-center">
                        <div className="author-name media-body">
                          <h5 className="name">{title}</h5>
                          <span className="sub-title">{subTitle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
