import { FC, HTMLAttributes } from "react";

enum SocialMediaIcon {
  LINKEDIN = "lni lni-linkedin-original",
  INSTAGRAM = "lni lni-instagram",
  GITHUB = "lni lni-github-original",
  LINK = "lni lni-link",
  TWITTER = "lni lni-twitter-original",
}

interface TeamData {
  title: string;
  subTitle: string;
  team: {
    youngLeads: TeamMember[];
    leads: TeamMember[];
    experts: TeamMember[];
    supporters: TeamMember[];
  };
}
interface TeamMember {
  name: string;
  title: string;
  subTitle: string[];
  image: string;
  social: SocialLinkData[];
}

interface SocialLinkData {
  link: string;
  icon: SocialMediaIcon;
}
const ASSETS_SERVER = "https://assets.u2c.biz";
const data: TeamData = {
  title: "Yönetim Ekibimiz",
  subTitle: "Bizde Direksiyon Gençlerde!",
  team: {
    youngLeads: [
      {
        name: "Halil Atak",
        title: "U2C Yönetim Lideri",
        subTitle: ["İş Geliştirme ve Dijital Pazarlama Danışmanı - Girişimci"],
        image: "halil_atak.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/halil-atak-60b319170/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Sevgi Tanboğa",
        title: "U2C İletişim Lideri",
        subTitle: [
          "İBB Sürdürülebilir Kentsel Gelişim Ağı Genel Kurul Üyesi",
          "İzelman A.Ş. Sosyal Hizmet Uzmanı",
        ],
        image: "sevgi_tanboga.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/sevgi-tanbo%C4%9Fa-033316177/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Cemre Ezgi Okşar",
        title: "U2C Sosyal Medya Lideri",
        subTitle: ["ODTÜ Psikoliji Öğrencisi"],
        image: "cemre_ezgi_oksar.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/cemreezgiok%C5%9Far1/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Mustafa Deniz Kantar",
        title: "U2C Üniversite İletişim ve Network Lideri",
        subTitle: ["BAU Ekonomi Bölümü Öğrencisi", "Yatırımcı - Girişimci"],
        image: "mustafa_deniz_kantar.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/mustafadenizkantar",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Yusuf Mısırlı",
        title: "U2C Organizasyon ve Koordinasyon Lideri",
        subTitle: [
          "Mersin Üniversitesi",
          "Kimya Bölümü Öğrencisi",
          "Girişimci",
        ],
        image: "yusuf_misirli.jpg",
        social: [
          {
            link: "http://linkedin.com/in/yusuf-misirli-77b62a250",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
    ],
    leads: [
      {
        name: "Özlem Aşkın-Sakarya",
        title: "U2C Fikir Annesi",
        subTitle: [
          "Değişim Öncüsü -",
          "BP Almanya'da Disiplin Yönetimi Lideri",
        ],
        image: "ozlem_sakarya.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/%C3%B6zlem-askin-sakarya-26270582/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Murat Ürüm",
        title: "U2C Network Kurucusu ve Lideri",
        subTitle: ["Mentör - Danışman - Sivil Toplum Gönüllüsü"],
        image: "murat_urum.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/murat-%C3%BCr%C3%BCm-115196174/",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://www.instagram.com/urum.m/",
            icon: SocialMediaIcon.INSTAGRAM,
          },
        ],
      },
      {
        name: "Uğur Öncü",
        title: "U2C Proje Yönetimi Danışmanı",
        subTitle: ["Yenilikçi Değişim Lideri - Borusan Otomotiv'de Yönetici"],
        image: "ugur_oncu.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/uguroncu/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
    ],
    experts: [
      {
        name: "Dilek Solcun",
        title: "U2C Araştırma ve Kalite Lideri",
        subTitle: ["Sosyal Girişimci - Eğitmen - Mentör"],
        image: "dilek_solcun.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/dilek-solcun-b29b73253/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Burcu Baycan",
        title: "U2C Kariyer Danışmanı",
        subTitle: [
          "Yeditepe Üniversitesi Kariyer Gelişim ve Mezunlarla Iletişim Müdürü",
        ],
        image: "burcu_baycan.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/burcubaycan/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Elif Varan",
        title: "U2C Kurumsal İlişkiler ve İletişim Lideri",
        subTitle: ["TEDxAntalya Co-Organizer, Partner @homeofdreamersco"],
        image: "elif_varan.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/elif-varan-637008a",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://www.instagram.com/wineaura/",
            icon: SocialMediaIcon.INSTAGRAM,
          },
        ],
      },
      {
        name: "Betul Urut Yılmaz",
        title:
          "U2C Girişimcilik & Kariyer Danışmanı, Mentör Programları Lideri",
        subTitle: ["Girişimci, Çiftçi (Tarla Kuşu), Mentör ve Kariyer Koçu"],
        image: "betul_urut_yilmaz.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/betulurutyilmaz",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://www.instagram.com/tarla.kusu_/",
            icon: SocialMediaIcon.INSTAGRAM,
          },
        ],
      },
      {
        name: "Sümeyye Karakoç",
        title: "U2C İşe Alım Danışmanı",
        subTitle: ["Michael Page Senior Consultant"],
        image: "sumeyye_karakoc.jpg",
        social: [
          {
            link: "http://linkedin.com/in/sümeyye-karakoç-98a88a152",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Murad Tiryakioğlu",
        title: "U2C Akademi Lideri",
        subTitle: ["Akademisyen - “Yerli Yeşil Yeni” Kurucusu"],
        image: "murad_tiryakioglu.jpg",
        social: [
          {
            link: "https://twitter.com/muratiryakioglu",
            icon: SocialMediaIcon.TWITTER,
          },
          {
            link: "https://www.linkedin.com/in/muradtiryakioglu",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "http://yerliyesilyeni.org/",
            icon: SocialMediaIcon.LINK,
          },
        ],
      },
      {
        name: "Özlem Dörtkardeşler",
        title: "U2C Hukuk Danışmanı",
        subTitle: ["Hukuk Gönüllüsü - Avukat"],
        image: "ozlem_dortkardesler.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/ozlem-dortkardesler-a6606a108/",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://www.instagram.com/av.ozlemdortkardesler",
            icon: SocialMediaIcon.INSTAGRAM,
          },
        ],
      },
    ],
    supporters: [
      {
        name: "Prof. Dr. Ela Ünler",
        title: "Bahçeşehir Üniversitesi",
        subTitle: [""],
        image: "ela_unler.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/ela-%C3%BCnler-64233226/",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://www.instagram.com/ela_unler/",
            icon: SocialMediaIcon.TWITTER,
          },
        ],
      },
      {
        name: "Prof. Dr. Eftade Gaga",
        title: "Eskişehir Teknik Üniversitesi",
        subTitle: [""],
        image: "eftade_gaga.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/eftade-gaga-95582016",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Aslı Güven",
        title: "İK Profesyoneli-Majorel Türkiye CHRO - Endüstri Mühendisi",
        subTitle: [""],
        image: "asli_guven.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/asl%C4%B1-g%C3%BCven-a650b466/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Emre Sakarya",
        title: "Yazılım ve Web Sitesi Geliştirme Uzmanı",
        subTitle: [""],
        image: "emre_sakarya.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/emresakarya/",
            icon: SocialMediaIcon.LINKEDIN,
          },
          {
            link: "https://github.com/softberry",
            icon: SocialMediaIcon.GITHUB,
          },
        ],
      },
      {
        name: "Memduh Özmert",
        title: "“Viktor Frankl Enstitüsü Türkiye” Kurucusu",
        subTitle: [""],
        image: "memduh_ozmert.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/memduh-n-ozmert-a7b2391/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Neslihan Özmert",
        title: " “Viktor Frankl Enstitüsü Türkiye” Kıdemli Danışmanı",
        subTitle: [""],
        image: "neslihan_ilci_ozmert.jpg",
        social: [
          {
            link: "ttps://www.linkedin.com/in/neslihan-%C3%B6zmert-67897015/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Suna Özpar",
        title: "İnsan kaynakları Danışmanı",
        subTitle: [""],
        image: "suna_ozpar.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/sunaozpar/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Selda Gülay Kaplan",
        title: "SGP / Video Prodüksiyon",
        subTitle: [""],
        image: "selda_gunay_kaplan.jpg",
        social: [
          {
            link: "https://sgp.istanbul/contact.php",
            icon: SocialMediaIcon.LINK,
          },
        ],
      },
      {
        name: "Volkan Kaplan",
        title: "SGP / Video Prodüksiyon",
        subTitle: [""],
        image: "volkan_kaplan.jpg",
        social: [
          {
            link: "https://sgp.istanbul/contact.php",
            icon: SocialMediaIcon.LINK,
          },
        ],
      },
      {
        name: "Ekrem Namazcı",
        title: "Co-Founder at GDEXA",
        subTitle: [""],
        image: "ekrem_namazci.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/ekremn/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
      {
        name: "Kaan Atagün",
        title: "Marka Patent Uzmanı",
        subTitle: [""],
        image: "kaan_atagun.jpg",
        social: [
          {
            link: "https://www.linkedin.com/in/kaan-atag%C3%BCn-28a06455/",
            icon: SocialMediaIcon.LINKEDIN,
          },
        ],
      },
    ],
  },
};

interface TeamGroupProps {
  htmlProps: HTMLAttributes<HTMLDivElement>;
  members: TeamMember[];
}
const TeamGroup: FC<TeamGroupProps> = ({ htmlProps, members }) => {
  return (
    <>
      {members.map(({ name, title, subTitle, image, social }, key) => (
        <div {...htmlProps} key={key}>
          <div
            className="team-style-eleven text-center mt-30 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0s"
            key={key}
          >
            <div className="team-image">
              <img src={`${ASSETS_SERVER}/images/team/${image}`} alt={name} />
            </div>
            <div className="team-content">
              <div className="team-social">
                <ul className="social">
                  {social.map(({ icon, link }, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noreferrer">
                        <i className={icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <h4 className="team-name">
                <span>{name}</span>
              </h4>
              <span className="sub-title">{title}</span>
              <div className="sub-title-more">
                {subTitle.map((t) => (
                  <div>{t}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export const OurTeam: FC = () => (
  <>
    <section
      className="slider_area"
      style={{ backgroundColor: "#162e60", height: 96 }}
    ></section>
    <section id="team" className="team-area pt-120">
      <div className="container pb-30">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-title text-center pb-30">
              <h3 className="title">{data.title}</h3>
              <h4>{data.subTitle}</h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <TeamGroup
            htmlProps={{ className: "col-sm-6 col-lg-4" }}
            members={data.team.youngLeads}
          />
        </div>
      </div>
      <div className="container  pb-130">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10"></div>
        </div>
        <div className="row justify-content-center">
          <TeamGroup
            htmlProps={{ className: "col-lg-3 col-sm-6" }}
            members={data.team.leads}
          />
        </div>
        <div className="row justify-content-center">
          <TeamGroup
            htmlProps={{ className: "col-lg-3 col-sm-6" }}
            members={data.team.experts}
          />
        </div>
      </div>
      <div className="container  pb-30">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-title text-center pb-30">
              <h3 className="title">Destekçilerimiz</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <TeamGroup
            htmlProps={{ className: "col-lg-3 col-sm-4" }}
            members={data.team.supporters}
          />
        </div>
      </div>
    </section>
  </>
);
