import { FC } from "react";

export const Logo: FC<{ dark: boolean }> = ({ dark }) => {
  const fill = dark ? "#162E60" : "#FFFFFF";
  return (
    <svg
      viewBox="0 0 720 300"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      version="1.1"
      enableBackground="new 0 0 940 940"
      role="image"
    >
      <desc>
        U2C Logosunun açılımı 'University To Career' dir. 'U' harfinin üzerinde
        mezuniyet kepi ve 'C' harfinin içinde merdivenleri tırmanan genç vardır.
      </desc>
      <g>
        <g>
          <text
            x="-113.52903"
            y="-203.95505"
            id="svg_1"
            fontSize="322.959px"
            fontFamily="'Algerian'"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            transform="matrix(1.0799 0 0 1 191.88 494.14)"
            className="st0 st1 st2"
          >
            U2C
          </text>
          <path
            id="svg_2"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            d="m222.8,17.04495c-1.1,1 -2,2.2 -3.2,2.9c-31.8,17.7 -63.6,35.4 -95.4,53c-1.6,0.9 -3.8,1.4 -5.7,1.2c-20.2,-1.6 -40.5,-3.2 -60.7,-5c-2.5,-0.2 -3.7,0.6 -4.7,2.6c-3.2,6.6 -3.3,13.5 -3,20.5c0.1,3.3 1,5.4 4.3,7.2c5.3,2.9 6.3,10.6 2.5,15.8c-1.2,1.6 -1.2,2.8 -0.5,4.5c4.4,11.7 8.7,23.5 13,35.2c0.8,2.1 -0.2,3.7 -2.4,4.3c-5.9,1.4 -11.8,2.7 -17.8,3.8c-2.5,0.5 -3.8,-0.9 -4,-3.3c-0.6,-8.2 -1.2,-16.4 -1.8,-24.6c-0.3,-4.6 -0.6,-9.2 -1.1,-13.8c-0.1,-0.7 -0.7,-1.7 -1.3,-2.1c-7.5,-4.7 -8.6,-11.4 -3.3,-18.6c0.4,-0.6 0.8,-1.5 0.7,-2.2c-1.4,-10.3 -1.2,-20.4 2.9,-30.7c-6.9,-0.5 -13.5,-1.1 -20,-1.6c-4,-0.3 -8,-0.6 -12,-1c-1.3,-0.1 -2.5,-0.8 -3.8,-1.2c-0.2,-0.8 -0.3,-1.6 -0.5,-2.4c1.2,-1 2.2,-2.3 3.5,-3c22.5,-12.6 45.1,-25.1 67.7,-37.6c9.7,-5.4 19.3,-10.9 28.9,-16.3c0.8,-0.2 1.7,-0.4 2.5,-0.5c0.9,0.2 1.8,0.4 2.8,0.5c35.6,2.9 71.2,5.7 106.9,8.6c1.7,0.1 3.4,0.9 5.1,1.4c0.1,0.8 0.2,1.6 0.4,2.4z"
            className="st0"
          />
          <path
            id="svg_3"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            d="m59,67.64495c19,1.6 40.1,3.7 58.6,5.3c4.4,0.4 3.5,0.4 7.4,-1.8c15.5,-8.7 25.4,-12.9 40.9,-21.6c0.7,-0.4 9.1,-6 10.1,-6.5c2,7.1 6.8,26.2 8.8,33.2c0.3,1.1 0.4,2.3 0.8,3.3c3.6,7.9 -0.1,13.7 -5.9,18.9c-7.8,7 -17.2,11.5 -27,15.1c-19.3,7 -39.1,10.9 -59.7,9.2c-6.1,-0.5 -12.3,-2.1 -18.1,-3.9c-5.7,-1.8 -8.8,-6 -9.5,-12.2c-0.9,-8.7 -5,-30 -6.4,-39z"
            className="st0"
          />
          <path
            id="svg_4"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            d="m618.8,200.74495c2.5,1.7 4.6,3 6.6,4.5c0.4,0.3 0.6,1 0.7,1.6c0.3,3.5 0.6,7.1 0.9,10.6c0.2,2.8 1.7,4.4 4.1,4.2c2.2,-0.1 3.6,-2 3.3,-4.7c-0.3,-4.2 -0.7,-8.4 -1,-12.7c-0.1,-1.7 -0.8,-2.9 -2.3,-3.9c-5.2,-3.5 -10.4,-7.2 -15.5,-10.7c-2.3,-1.6 -5,-0.9 -6.7,1.2c-3.4,4.4 -7,8.6 -10.6,12.8c-0.4,0.4 -1.2,0.7 -1.8,0.8c-3.3,0.4 -6.7,0.7 -10,1.1c-2.3,0.3 -3.9,1.9 -3.7,3.7c0.2,2.1 1.9,3.4 4.4,3.2c4.3,-0.3 8.5,-0.7 12.8,-1.4c1.1,-0.2 2.3,-1.1 3.2,-1.9c1.2,-1.1 2.2,-2.4 3.7,-4.1c0,4.2 0,8 -0.1,11.7c0,0.4 -0.8,0.8 -1.4,1.1c-3.3,1.5 -6.7,3 -10,4.5c-2.8,1.3 -3.5,2.5 -3.3,5.4c0.3,4.2 0.5,8.4 0.9,12.7c0.2,2.8 2.2,4.4 4.9,4.1c2.5,-0.2 4,-2.1 3.9,-4.9c-0.2,-3 -0.3,-6 -0.6,-9c-0.1,-1.2 0.3,-1.8 1.5,-2.3c2.3,-0.9 4.5,-2 7.1,-3.1c0.7,2.7 1.4,5.3 2.1,8c1.8,6.9 3.5,13.8 5.3,20.7c0.1,0.4 0.2,0.8 0.4,1.2c0.9,2.4 3.2,3.6 5.6,3c2.3,-0.6 3.6,-2.6 3.1,-5.1c-0.7,-3.2 -1.5,-6.3 -2.3,-9.4c-1.7,-6.4 -3.4,-12.9 -4.9,-19.4c-0.4,-2 0,-4.1 0,-6.1c-0.3,-5.5 -0.3,-11.2 -0.3,-17.4z"
            className="st0"
          />
          <path
            id="svg_5"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            d="m610.5,260.44495c0,-4.1 -0.1,-7.8 0,-11.6c0,-1.8 -0.7,-2.5 -2.6,-2.5c-7.1,0.1 -14.2,0 -21.6,0c0,-3.5 -0.2,-6.8 0.1,-10.1c0.2,-2.5 -0.8,-4 -2.8,-5.4c-3.3,-2.2 -6.4,-4.7 -9.7,-7.3c0.8,-0.8 1.4,-1.5 2.4,-2.4c-3.6,-1.1 -7.1,-2.1 -10.9,-3.2c1.2,3.6 2.2,6.8 3.4,10.3c1.1,-0.9 2,-1.7 3,-2.6c3.7,2.8 7.4,5.5 11.1,8.3c0.4,0.3 0.5,1.2 0.5,1.8l0,10.7c0,2.3 0.4,2.7 2.8,2.7l21.4,0c0,4 0.1,7.9 0,11.7c0,1.8 0.7,2.4 2.5,2.3c7.4,-0.1 14.7,0 22.1,-0.1c0.8,0 2.2,-0.8 2.3,-1.4c0.2,-1.3 -1.1,-1.4 -2.3,-1.4l-19.3,0c-0.8,0.2 -1.5,0.2 -2.4,0.2z"
            className="st0"
          />
          <path
            id="svg_6"
            strokeMiterlimit="10"
            stroke="#11100F"
            fill={fill}
            d="m609.2,187.94495c5,-0.1 9.2,-4 9.1,-8.6c0,-4.8 -4.4,-8.9 -9.5,-8.8s-9.2,4.2 -9.1,8.9c0.1,4.9 4.3,8.6 9.5,8.5z"
            className="st0"
          />
        </g>
      </g>
    </svg>
  );
};
