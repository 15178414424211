import { FC } from "react";
import { Logo } from "./Logo";

export const Footer: FC = () => (
  <section className="footer-area footer-dark">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="footer-logo text-center">
            <a className="mt-30" href="/">
              <Logo dark={false} />
            </a>
          </div>
          <ul className="social text-center mt-60">
            <li>
              <a
                href="https://www.linkedin.com/company/isteegitim"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <i className="lni lni-linkedin-original" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/University2Career/"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="lni lni-facebook-filled" aria-hidden="true"></i>
                <span className="visually-hidden"></span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/u2c_biz"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <i className="lni lni-twitter-original" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/u2c.biz/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i
                  className="lni lni-instagram-original"
                  aria-hidden="true"
                ></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCfplQx1lxnVQZ5eJBAKswjw"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube"
              >
                <i className="lni lni-youtube" aria-hidden="true"></i>
              </a>
            </li>
          </ul>

          <div className="footer-support text-center">
            <span className="mail" aria-label="Email adresimiz">
              info@u2c.biz
            </span>
            <br />
            <div>
              U2C İş'te Eğitim Derneği çatısı altında yürütülen bir sosyal
              girişimdir.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
