import { FC } from "react";
import { TALENT_POOL_FORM_URL } from "../libs";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const ShareButtons: {
  ShareButton: typeof FacebookShareButton;
  ShareIcon: typeof FacebookIcon;
}[] = [
  { ShareButton: LinkedinShareButton, ShareIcon: LinkedinIcon },
  { ShareButton: EmailShareButton, ShareIcon: EmailIcon },
  { ShareButton: FacebookShareButton, ShareIcon: FacebookIcon },
  { ShareButton: TwitterShareButton, ShareIcon: TwitterIcon },
];
export const TalentPool: FC = () => {
  const handleJoinNow = () => {
    window.open(TALENT_POOL_FORM_URL, "_blank");
  };
  return (
    <>
      <section
        className="slider_area"
        style={{ backgroundColor: "#162e60", height: 96 }}
      ></section>
      <div>
        <section className="features-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10">
                <div className="section-title text-center pb-10">
                  <h3 className="title">Yetenek Havuzu</h3>
                  <p className="text">
                    U2C sürekli büyüyen bir yetenek havuzuna sahiptir. Bu
                    yetenek havuzunda, eğitimi devam ederken çalışmak isteyen
                    üniversite öğrencileri bulunmaktadır. U2C ağında bulunan
                    şirketlerin kriterlerine göre, U2C tarafından eşleştirme ve
                    ön görüşme yapılarak uygun adaylar belirlenir.
                  </p>
                  <p className="text">
                    Eşleştirilen öğrenciler, şirketlerin işe alım proseslerine
                    dahil edilirler. İşverenler tarafından gerçekleştirilen
                    mülakatlar sonrasında işe alım gerçekleştirilir. U2C tüm
                    sürecin işleyişini ve organizasyonunu takip eder.
                  </p>
                  <p className="text">
                    Sen de üniversitede okurken çalışmak istiyorsan yetenek
                    havuzumuza katılmak için başvuru yapabilirsin.
                  </p>

                  <p className="text">
                    <button
                      onClick={handleJoinNow}
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Hemen başvur
                    </button>
                  </p>

                  <p>
                    <br />
                    {ShareButtons.map(({ ShareButton, ShareIcon }, i) => (
                      <ShareButton key={i} url={window.location.href}>
                        <ShareIcon size={32} round />
                      </ShareButton>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
