import { FC } from "react";
const data = [
  {
    link: "https://data.tuik.gov.tr/Bulten/Index?p=Isgucu-Istatistikleri-Ocak-2021-37486#:~:text=15%2D24%20ya%C5%9F%20grubunu%20kapsayan,%39%2C9%20seviyesinde%20ger%C3%A7ekle%C5%9Fti",
    title: "Genç İşsizliği",
    text: "2021 TÜİK verilerine göre Türkiye’de her 4 gençten biri işsiz.Üniversiteden mezun gençlerin ortalama iş bulma süresi 3 yıl.",
    percent: 25,
  },
  {
    link: "https://yeditepe.edu.tr/tr/genclerin-yuzde-76si-daha-iyi-bir-gelecek-icin-yurt-disinda-yasamak-istiyor#:~:text=18%2D29%20ya%C5%9F%20grubu%20aras%C4%B1nda,yetenekten%20daha%20etkili%20oldu%C4%9Funa%20inan%C4%B1yor",
    title: "Beyin Göçü",
    text: "MAK ve Yeditepe Üniversitesi araştırmasına göre gençlerin %76’sı imkanı olsa hayatına yurtdışında devam etmek istiyor. Bunun en büyük sebebi olarak da gelecek kaygısı gösteriliyor.",
    percent: 76,
  },
  {
    link: "https://stats.oecd.org/Index.aspx?DataSetCode=MISMATCH",
    title: "Yanlış Eşleşme",
    text: "OECD İş gücü istatistiklerine göre Türkiye’de aktif iş hayatında bulunan insanların %43’ü kendi ilgi ve yeteneklerine göre yanlış bir alanda çalışıyor.",
    percent: 43,
  },
];
export const Missions: FC = () => (
  <section className="features-area">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-10">
          <div className="section-title text-center pb-10">
            <h3 className="title">Varoluş Nedenlerimiz</h3>
            <p className="text">
              Varlığı her geçen gün daha çok insanı olumsuz etkileyen ve
              toplumsal gelişime engel olan 3 probleme çözüm getirmek üzere yola
              çıktık.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {data.map(({ link, text, title, percent }, key) => (
          <a
            className="col-lg-4 col-md-7 col-sm-9"
            href={link}
            target="_blank"
            rel="noreferrer"
            key={key}
          >
            <div className="single-features mt-40">
              <div className="features-title-icon d-flex justify-content-between">
                <h4 className="features-title">{title}</h4>
                <div className="percent-chart">
                  <svg viewBox="0 0 36 36" className="circular-chart">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${percent}, 100`}
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="percentage">
                      %{percent}
                    </text>
                  </svg>
                </div>
              </div>
              <div className="features-content">
                <p className="text">{text}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  </section>
);
