import { FC } from "react";
import { VideoJs } from "./VideoJs";
import { createMobileVideo } from "../libs";

// TODO: Add accecibility description
// const data = {
//   description:
//     "Beyaz ekran üzerinde mavi karede U2C yazısı var •Mavi kare köşeye gelirken arkası dönük yürüyen bir erkek var •Mavi kare köşede Türkiye'de üniversite mezunu her 10 gençten 4'ü işsiz •Üniversite mezunu gençlerin sadece %35'i ancak 3 yıl içinde iş bulabiliyor. •İş bulma ümidini yitiren gençlerde ise son 3 yılda her yıl 250 bin genç ekleniyor yazıyor •( Görsel değişiyor.) Elinde dosya tutan bir kadın iş arkadaşlarına bir şeyler anlatıyor •( mavi köşede) Buna rağmen şirketler, nitelikli çalışan bulamamaktan dert yanıyor. •Bu genç işsiz sorununu dert edindik ve yola çıktık •Akademi,iş dünyası ve üniversite öğrencilerinin de olduğu gönüllü bir sosyal girişim olan U2C'yi kurduk. •Mavi halkalar üzerinde Peki U2C nedir? yazıyor •Bir erken istihdam uygulamasıdır. •(Görsel değişiyor)Tahtada bir konu anlatan erkek •(mavi köşede) Bir staj programı değildir •Bu uygulamanın Almanya başta olmak üzere birçok ülkede başarılı örnekleri mevcuttur. •Kapsamı bakımından Türkiye'de bir ilktir. •(Görsel değişiyor) siyah ekran üzerinde mavi kare kutuda; •U2C öğrencilerin üniversiteyi okurken alanlarında sigortalı olarak çalışmasını hedefler. •Teoriyi, pratikte görme fırsatı sunar •Üniversite-şirket-öğrenci işbirliği ile hedefine yürür. •(Görsel değişiyor.) Ellerini bir araya koyan kişiler •Bu yolda Six Sigma metodu kullanırız •Ve bizde direksiyon gençlerdedir.",
// };

export const Hero: FC = () => {
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    preload: true,
    poster: "https://assets.u2c.biz/videos/u2c-poster.jpg",
    fluid: true,
    sources: [
      {
        src: "https://assets.u2c.biz/videos/u2c-intro.mp4",
        type: "video/mp4",
      },
    ],
  };
  const handlePlayMobileVideo = () => {
    createMobileVideo();
  };
  return (
    <section className="slider_area">
      <div id="carouselThree" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <h1 className="title text-center">U2C Nedir?</h1>
                    <p className="text">
                      U2C (University to Career), Türkiye'de genç istihdamına
                      destek olmak amacıyla, öğrencilerin eğitimleri süresince
                      bir şirkette çalışarak deneyim kazanmasını hedefleyen bir
                      sosyal girişimdir.
                    </p>
                    <div className="d-lg-none">
                      <p
                        className="text text-center"
                        id="mobile-intro-player"
                        onClick={handlePlayMobileVideo}
                      >
                        <img
                          src="https://assets.u2c.biz/images/play-button.png"
                          loading="lazy"
                          alt="Play button"
                          aria-hidden="true"
                        />
                        <br /> Tanıtım videomuzu izleyin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-image-box d-none d-lg-flex align-items-center">
              <div className="slider-image">
                <div id="video-u2c-intro">
                  <VideoJs {...videoJsOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
