import { FC, useState } from "react";

const data = [
  {
    question: "U2C (University to Career) Nedir?",
    answer: [
      "Türkiye’de genç istihdamını desteklemek amacıyla oluşturulan U2C (University to Career), öğrencilerin eğitimleri süresince bir şirkette çalışarak deneyim kazanmasını hedefleyen bir sosyal girişimdir.",
    ],
  },
  {
    question: "U2C (University to Career) Avantajları Nelerdir?",
    answer: [
      "U2C erken istihdam uygulaması ile lisans eğitimine devam ederken şirketlerde çalışma imkanı bulup, mesleki bilgi ve becerilerinizi geliştirme fırsatı yakalayabilirsiniz. ",
      "Lisans programınız sırasında kurumsal bağlantılarınızı geliştirme firsatı bulabilirsiniz.",
    ],
  },
  {
    question: "U2C (University to Career) Kimler İçin Uygundur?",
    answer: [
      "Yüksek motivasyona sahip, zamanını koordine edebilen, lisans öğrenime devam ederken aynı zamanda bir şirkette çalışabilecek dayanıklılığa sahip kişiler için uygundur.",
    ],
  },
  {
    question: "U2C (University to Career) Ne Yapar?",
    answer: [
      "Üniversite-öğrenci şirket dinamiğini oluşturarak öğrencilere kariyerlerinin başlarında uygun şirketlerde mesleki bilgi ve tecrübe kazandırır.",
      "Şirket ve öğrenci ilişkisini öğrencinin eğitim döneminde kurarak işverenlerin kriterlerine uygun adayları bulmadaki güçlüklerini ortadan kaldırır.",
      "Yeni mezunların U2C Programında kazandığı iş tecrübesiyle şirketlerde pozisyon bulabilmelerini sağlar.",
    ],
  },
];
export const Accordeon: FC = () => {
  const [selectedItem, setSelectdItem] = useState(0);
  const handleClick = (key: number) => {
    setSelectdItem(key);
  };
  return (
    <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="faq-content mt-45">
              <div className="about-title">
                <h6 className="sub-title">SIKCA SORULAN SORULAR</h6>
              </div>

              <div className="about-accordion">
                <div className="accordion" id="faqAccordion">
                  {data.map(({ question, answer }, key) => (
                    <div className="card" key={key}>
                      <div className="card-header" id="headingOne">
                        <a
                          aria-expanded={key === selectedItem}
                          className={key !== selectedItem ? "" : "collapsed"}
                          onClick={() => handleClick(key)}
                          href={`#faq-${key}`}
                        >
                          {question}
                        </a>
                      </div>

                      <div
                        className={
                          key === selectedItem ? "collapse show" : "collapse"
                        }
                        aria-labelledby="headingOne"
                        data-parent="#faqAccordion"
                      >
                        <div className="card-body">
                          <p className="text">{answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-image mt-50">
              <img
                src="https://assets.u2c.biz/images/about_us.jpeg"
                loading="lazy"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
