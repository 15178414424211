import { isInViewport } from "./in-viewport";

export const TALENT_POOL_FORM_URL =
  "https://docs.google.com/forms/d/1NQmmtrGZ0PE8Y8ATkoLnfTm3zjE6mPc2WjWi_7eAuJg/";
export const interestFormDialog = (): void => {
  const dialog = $("#interestForm");
  const noInterestButton = dialog.find("[data-no-interest]");
  const joinNowButton = dialog.find("[data-join-now]");

  const showModal = localStorage.getItem("INTERESTED_IN_TALENTPOOL") !== "NO";
  noInterestButton.on("click", () => {
    localStorage.setItem("INTERESTED_IN_TALENTPOOL", "NO");
  });

  joinNowButton.on("click", () => {
    localStorage.setItem("INTERESTED_IN_TALENTPOOL", "NO");
    window.open(TALENT_POOL_FORM_URL, "_blank");
  });

  showModal && dialog.modal("show");
};

export const initEyeCatcherAnimation = (): void => {
  const alertContainer = $(".talent-pool__alert");
  if (!alertContainer) return;
  const inViewPort = isInViewport(".talent-pool__alert");
  if (inViewPort && alertContainer.not(".once-animated").length === 1) {
    alertContainer.addClass("shake-slow shake-constant once-animated");
    setTimeout(() => {
      alertContainer.removeClass("shake-slow shake-constant");
    }, 1000);
  }
};
